<ng-container *ngIf="theme.type == 'shop'">
    <div class="img-wrapper">
        <div class="front">
            <a [routerLink]="['/product/' + products.handleUrl]">
                <img alt="" class="img-fluid" [src]="products.image[0]?.url">
            </a>
        </div>
        <div class="cart-info cart-wrap">
            <!-- <button (click)="addToCart(products)" title="Add to cart"><i class="fa fa-shopping-cart" ></i></button>
            <a (click)="addToWishlist(products)" title="Add to Wishlist"><i class="fa fa-heart-o" aria-hidden="true"></i></a>
            <a routerLinkActive="active" (click)="addToCompare(products)" title="Compare"><i class="fa fa-refresh"aria-hidden="true"></i></a> -->
            <a href="javascript:void(0)" title="Hızlı İncele" (click)="openQuickPreview(products.handleUrl)"><i class="fa fa-search" aria-hidden="true"></i></a>
        </div>
    </div>
    <div class="product-detail">
        <a [routerLink]="['/product/' + products.handleUrl]">
            <h6>{{products.title }}</h6>
        </a>
        <p>{{products.subtitle}}</p>
        <h4 class="">{{products.displayPrice}} TL</h4>
    </div>
</ng-container>
<ng-container *ngIf="theme.type == 'gallery'">
    <div class="main">
        <div class="bg-overlay d-flex align-items-center justify-content-center">
            <i class="fa fa-expand"></i>
        </div>
        <div class="img-wrapper">
            <div class="front">
                <img alt="" class="img-fluid" [src]="products.image[0]?.url">
            </div>
        </div>
        <div class="product-detail">
            <h6>{{products.title}}</h6>
            <p>{{products.subtitle}}</p>
        </div>
    </div>
</ng-container>
