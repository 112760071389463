import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  
  companyInfos = {
    name: 'Zafer Çiçekçilik',
    contact: {
      address: 'Barbaros, 5212. Sk. No:72, 35090 Bornova/İzmir',
      phones: [
        {
          title: 'Telefon',
          no: '0 232 461 11 87'
        },
        {
          title: 'Telefon',
          no: '0 232 462 23 98'
        }
      ],
      fullName: '',      
      fax: '0 232 486 32 67',
      email: 'zafer@zafercicekcilik.com.tr'
    },
    social: [
      // {
      //   icon: 'fa fa-twitter',
      //   link: 'https://twitter.com'
      // }
    ]
  }
  theme = {
    type: 'gallery', // shop - gallery
    title: 'Zafer Çiçekçilik',
    logo: {
      selected: 'image', // text - image
      text: 'Zafer Çiçekçilik',
      headerLogo: 'assets/images/logo/zafer-logo.png',
      footerLogo: 'assets/images/logo/zafer-logo-shadow.png'
    },
    favicon: 'assets/images/icon/zafer-icon.png',
    maintenance: {
      active: false,
      leftImage: 'assets/images/zafer-coming-soon.jpg'
    },
    showNoStock: 1
  }

  constructor() { }

}
