import { Component, OnInit } from '@angular/core';
import { NavService, Menu } from '../../../service/nav.service';
import { CollectionService } from 'src/app/shared/service/collection.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  public menuItems: Menu[];
  public openSide : boolean = false;
  public activeItem: string = 'home';
  public active: boolean = false;
  public activeChildItem : string = '' 
  public overlay: boolean = false;;
  
  constructor( 
    public navServices: NavService,
    public collectionService: CollectionService,
    private router: Router
    ) {
      
    }
 
  ngOnInit() {
    this.getCollections();
    this.navServices.items.subscribe(menuItems => {
      this.menuItems = menuItems
    });
  }

  getCollections() {
    this.collectionService.listTree().subscribe(res => {
      console.log('pq' , res)
    })
  }

  toggleSidebar(){
    this.openSide = !this.openSide
  }

  closeOverlay(){
    this.openSide = false
  }

  // setActive(menuItem, id){
  //   if (this.activeItem === menuItem) {
  //     this.activeItem = ''
  //   } else {
  //     this.activeItem = menuItem
  //   }

  //   this.router.navigate(['/collection'], { queryParams: { koleksiyon: id } });

  // }

  isActive(item){
    return this.activeItem === item 
  }

  // setChildActive(subMenu, collection){
  //   if (this.activeChildItem === subMenu) {
  //     this.activeChildItem = ''
  //   } else {
  //     this.activeChildItem = subMenu
  //   }
    
  //   this.router.navigate(['/collection'], { queryParams : { koleksiyon: collection.Item.Id }});
  // }

  ischildActive(subMenu){
    return this.activeChildItem === subMenu 
  }


  convertString(phrase) {
  
      var newUrl = phrase.toLowerCase();
      
      newUrl = newUrl.replace(/ö/g, 'o');
      newUrl = newUrl.replace(/ç/g, 'c');
      newUrl = newUrl.replace(/ş/g, 's');
      newUrl = newUrl.replace(/ı/g, 'i');
      newUrl = newUrl.replace(/ğ/g, 'g');
      newUrl = newUrl.replace(/ü/g, 'u');  
  
  
      newUrl = newUrl.replace(/[^a-z0-9\s-]/g, "");
      newUrl
      newUrl = newUrl.replace(/[\s-]+/g, " ");
  
      newUrl = newUrl.replace(/^\s+|\s+$/g,"");
  
  
      newUrl = newUrl.replace(/\s/g, "-");  
  
      return newUrl;
      
  }
}

