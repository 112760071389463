import { Component, OnInit } from '@angular/core';
import { CartService } from '../../../service/cart.service';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/shared/service/account.service';
import { CollectionService } from 'src/app/shared/service/collection.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SettingsService } from 'src/app/shared/service/settings.service';

@Component({
  selector: 'app-ecommerce-header',
  templateUrl: './ecommerce-header.component.html',
  styleUrls: ['./ecommerce-header.component.scss']
})
export class EcommerceHeaderComponent implements OnInit {
  public shoppingCartItems  :   any[] = [];
  public  showItem: boolean = false;
  theme;
  searchText = '';
  constructor(
    public cartService: CartService,
    private toastr: ToastrService,
    public accountService: AccountService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private collectionService: CollectionService,
    private settingsService: SettingsService
  ) { 
    this.theme = settingsService.theme;
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      const arama = params['arama']
      if(arama) {
        this.searchText = arama;
      }
    })

    /* this.cartService.getCart().subscribe(res => {
      if (res['result']['status']) {
        this.shoppingCartItems = res['data'];
      }
    }) */

    this.cartService.cartItemCount.subscribe(res => {
      this.cartService.getCart().subscribe(res => {
        if (res['result']['status']) {
          this.shoppingCartItems = res['data'];

          this.cartService.getCartSumamry().subscribe(res => { });
        }
      })
    })
    
  }

  removeItem(item) {
    let index = this.shoppingCartItems.findIndex(r => item.SepetGuid == r.SepetGuid);
    if (index >= 0) {
      this.cartService.deleteRow(item.SepetGuid).subscribe(res => {
        if (res['status']) {
          this.shoppingCartItems.splice(index, 1);
          this.cartService.cartItemCount.next(1);
          this.toastr.success(res['description']);
        } else {
          this.toastr.error(res['description']);
        }
      })
    }
  }

  get getSubTotal():number {
    let subtotal = 0;
    for (const item of this.shoppingCartItems) {
      subtotal += item.DiscountAmount;
    }
    return  subtotal;
  }

  logout() {
    this.accountService.logout();
  }

  search() {
    if (this.router.url.indexOf('urunler') > 0) {
      this.collectionService.productListDetector.next(this.searchText);
    }else {
      this.router.navigate([`/urunler`], { queryParams: { arama: this.searchText } });
    }
  }
}
