import { Component, OnInit } from '@angular/core';
import { BannerService } from 'src/app/shared/service/banners';
import { CollectionService } from 'src/app/shared/service/collection.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ecommerce-banner-slider',
  templateUrl: './ecommerce-banner-slider.component.html',
  styleUrls: ['./ecommerce-banner-slider.component.scss']
})
export class EcommerceBannerSliderComponent implements OnInit {
  bannerList;
  bannerCount;
  bannerLoader = true;
  collectionList;
  collectionCount;
  collectionLoader = true;


  constructor(
     private bannerService: BannerService,
    private collectionService: CollectionService,
    private router: Router
  ) { 
    this.bannerList = [];
    this.collectionList = [];
  }

  async getBanners() {
    this.bannerLoader = true;
    await this.bannerService.list().subscribe(res => {
      this.bannerList = res;
      this.bannerCount = res.length;
      this.bannerLoader = false;
    })
  }

  // async getCollections() {
  //   this.collectionLoader = true;
  //   await this.collectionService.listTree().subscribe(res => {
  //     this.collectionList = this.collectionService.getMainCollection;
  //     this.collectionCount = this.collectionService.getMainCollection.length;
  //     this.collectionLoader = false;
  //   })
  // }

  getCollections() {
    this.collectionLoader = true;
    this.collectionService.listTree().subscribe(res => {
      this.collectionList = this.collectionService.getMainCollection;
      this.collectionLoader = false;
    })
  }

  ngOnInit() { 
    this.getBanners();
    this.getCollections();
  }

  // navigateCollection(collection) {
  //   this.router.navigate([`/urunler`], { queryParams: { koleksiyon: collection.Id }});
  // }

  ecombannerOptions = {
    items: 1,
    nav: true,
    navClass: ['owl-prev', 'owl-next'],
    navText: ['<i class="icon-angle-left"></i>', '<i class="icon-angle-right"></i>'],
    dots: false,
    autoplay: true,
    slideSpeed: 300,
    loop: true,
    animateOut: 'fadeOut',
    smartSpeed:1500,
    // animateOut: 'slideOutRight',
    // animateIn: 'flipInX',
    autoplayHoverPause:true
  }

  navigateBanner(banner) {
    if(banner.YonlendirmeTipi == '0') {
      this.router.navigate([`/urunler`], { queryParams: { koleksiyon: banner.YonlendirmeId } });
    } else if (banner.YonlendirmeTipi == '1') {
      this.router.navigate([`/urun-detay`], banner.YonlendirmeId );
    } else {

    }
  }


  convertString(phrase) {
  
    var newUrl = phrase.toLowerCase();
    
    newUrl = newUrl.replace(/ö/g, 'o');
    newUrl = newUrl.replace(/ç/g, 'c');
    newUrl = newUrl.replace(/ş/g, 's');
    newUrl = newUrl.replace(/ı/g, 'i');
    newUrl = newUrl.replace(/ğ/g, 'g');
    newUrl = newUrl.replace(/ü/g, 'u');  


    newUrl = newUrl.replace(/[^a-z0-9\s-]/g, "");
    newUrl
    newUrl = newUrl.replace(/[\s-]+/g, " ");

    newUrl = newUrl.replace(/^\s+|\s+$/g,"");


    newUrl = newUrl.replace(/\s/g, "-");  

    return newUrl;
    
}

}