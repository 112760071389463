import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng5SliderModule } from 'ng5-slider';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { MenuComponent } from './components/navigation/menu/menu.component';
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';
import { HeaderComponent } from './components/pages/header/header.component';
import { BreadcrumbComponent } from './components/pages/breadcrumb/breadcrumb.component';
import { FooterComponent } from './components/pages/footer/footer.component';
import { RouterModule } from '@angular/router';
import { CenterMenuComponent } from './components/navigation/center-menu/center-menu.component';
import { ToastrModule } from 'ngx-toastr';
import { OrderByPipe } from './pipes/order-by.pipe';
import { LoaderComponent } from './components/loader/loader.component';

import { LoginService } from './service/login.service';
import { AccountService } from './service/account.service';
import { CollectionService } from './service/collection.service';
import { AuthGuard } from './guard/auth.guard';
import { CartService } from './service/cart.service';
import { PaymentService } from './service/payment.service';
import { AddressService } from './service/address';
import { CustomerService } from './service/customer-service';
import { OrderService } from './service/orders';
import { BannerService } from './service/banners';
import { CompanyService } from './service/company.service';
import { AppService } from './service/app.service';

import { EcommerceHeaderComponent } from './components/pages/ecommerce-header/ecommerce-header.component';
import { EcommerceFooterComponent } from './components/pages/ecommerce-footer/ecommerce-footer.component';
import { PriceComponent } from './components/filter/price/price.component';
import { ColorComponent } from './components/filter/color/color.component';
import { BrandComponent } from './components/filter/brand/brand.component';
import { CollectionComponent } from './components/filter/collection/collection.component';
import { ProductBoxComponent } from './components/widget/product-box/product-box.component';
import { ProductsService } from './service/products.service';
import { FilterService } from './service/filter.service';
import { QuickPreviewModalComponent } from './components/widget/product-box/quick-preview-modal/quick-preview-modal.component';
import { CartComponent } from './components/widget/cart/cart.component';
import { ProductDetailsComponent } from './components/widget/product-details/product-details.component';
import { ThreeDPayModalComponent } from '../pages/checkout/3dPay-modal/3dPay.modal.component';
import { EcommerceBannerSliderComponent } from './components/widget/ecommerce-banner-slider/ecommerce-banner-slider.component';
import { SettingsService } from './service/settings.service';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    Ng5SliderModule,
    NgbModule,
    CarouselModule,
    ToastrModule.forRoot()
  ],
  declarations: [
    LoaderComponent,
    MenuComponent, 
    TapToTopComponent,
    HeaderComponent,
    EcommerceHeaderComponent,
    EcommerceFooterComponent,
    BreadcrumbComponent,
    FooterComponent, 
    CenterMenuComponent, 
    OrderByPipe, 
    CartComponent,

    PriceComponent,
    ColorComponent,
    BrandComponent,
    CollectionComponent,
    ProductBoxComponent,
    QuickPreviewModalComponent,
    ProductDetailsComponent,
    ThreeDPayModalComponent,
    EcommerceBannerSliderComponent
  ],
  exports:[
    LoaderComponent,
    CommonModule,
    FormsModule,
    MenuComponent,
    CenterMenuComponent,
    TapToTopComponent,
    HeaderComponent,
    BreadcrumbComponent,
    FooterComponent,
    OrderByPipe,
    EcommerceHeaderComponent,
    EcommerceFooterComponent,
    CartComponent,
    EcommerceBannerSliderComponent,

    PriceComponent,
    ColorComponent,
    BrandComponent,
    CollectionComponent,
    ProductBoxComponent,
    QuickPreviewModalComponent,
    ProductDetailsComponent,
    ThreeDPayModalComponent
  ],
  providers: [
    AuthGuard,
    LoginService,
    AccountService,
    CollectionService,
    ProductsService,
    FilterService,
    CartService,
    PaymentService,
    AddressService,
    CustomerService,
    OrderService,
    BannerService,
    CompanyService,
    AppService,
    SettingsService
  ]
})
export class SharedModule { }
