<!-- Nav Start-->

<header class="ecommerce">
    <div class="container">
        <div class="row">
            <div class="col">
                <nav>
                    <a class="logo-erapper samsung-font-bold" [routerLink]="['']"  *ngIf="theme.logo.selected == 'image'"><img [src]="theme.logo.headerLogo" [alt]="theme.logo.text"></a>
                    <a class="logo-erapper samsung-font-bold" [routerLink]="['']"  *ngIf="theme.logo.selected == 'text'">{{ theme.logo.text }}</a>
                    <div class="search-product d-none d-sm-none d-md-flex">
                        <input [(ngModel)]="searchText" type="text" placeholder="Aradığınız ürün, kategori veya markayı yazınız" (keyup.enter)="search()">
                        <button (click)="search()">
                            <img src="assets/images/icon/search-icon.png" width="20" height="20" draggable="false" style="user-select: none;">
                        </button>
                    </div>
                    <div class="row align-items-center m-0">
                        <div class="top-header-right mr-0">
                            <ul>
                                <!-- <li class="search">
                                    <a aria-expanded="false" data-toggle="dropdown" href="javascript:void()" id="dropdownMenuButton">
                                        <i class="icon-search"></i>
                                    </a>
                                    <div aria-labelledby="dropdownMenuButton" class="dropdown-menu dropdown-menu-right">
                                        <form class="form-inline search-form">
                                            <div class="form-group">
                                                <label class="sr-only">Email</label>
                                                <input class="form-control-plaintext" placeholder="Search...." type="search">
                                                <span class="d-sm-none mobile-search">
                                </span>
                                            </div>
                                        </form>
                                    </div>
                                </li> -->
                                <li class="cart" *ngIf="theme.type == 'shop'">
                                    <a aria-expanded="false" data-toggle="dropdown" href="javascript:void(0)" id="dropdownMenuCart" [routerLink]="['/sepetim']">
                                        <i class="icon-shopping-cart-full"></i>
                                    </a>
                                    <span class="cart_qty_cls">{{cartService.cartSummary?.totalQuantity}}</span>
                                    <div aria-labelledby="dropdownMenuCart" class="dropdown-menu show dropdown-menu-right">
                                        <ul class="shopping-cart" *ngIf='shoppingCartItems.length' style="margin-top: -20px;">
                                            <li *ngFor="let item of shoppingCartItems">
                                                <div class="media">
                                                    <a [routerLink]="['/urun-detay', item.ProductId]" href="javascript:void(0)"><img class="mr-3" [src]="item?.ResimUrl" alt=""></a>
                                                    <div class="media-body">
                                                        <a [routerLink]="['/urun-detay', item.ProductId]">
                                                            <h6>{{ item?.ProductName }}</h6>
                                                        </a>
                                                        <h4><span>{{ item?.Quantity }} x {{ item?.SalePrice }}</span></h4>
                                                    </div>
                                                </div>
                                                <div class="close-circle">
                                                    <a (click)="removeItem(item)"><i class="fa fa-times" aria-hidden="true"></i></a>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="total">
                                                    <h5>Ara Toplam : <span>{{ getSubTotal }} TL</span></h5>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="buttons">
                                                    <a [routerLink]="'/sepetim'" class="view-cart">Sepete Git</a>
                                                    <a [routerLink]="'/odeme'" class="checkout">Ödeme Yap</a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="account">
                                    <a aria-expanded="false" data-toggle="dropdown" href="javascript:void(0)" id="dropdownMenuButton2">
                                        <i class="icon-user"></i>
                                    </a>
                                    <div aria-labelledby="dropdownMenuButton2" class="dropdown-menu show dropdown-menu-right">
                                        <a class="dropdown-item" href="javascript:void('0')" [routerLink]="['/hesabim']">HESABIM</a>
                                        <a class="dropdown-item" href="javascript:void('0')" [routerLink]="['/siparislerim']" *ngIf="theme.type == 'shop'">ŞİPARİŞLERİM</a>
                                        <a class="dropdown-item" href="javascript:void('0')" [routerLink]="['/musteri-hizmetleri', 0]">SSS</a>
                                        <a *ngIf="accountService.isloggedIn" class="dropdown-item" href="javascript:void('0')" (click)="logout()">ÇIKIŞ</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <app-menu class="d-block d-md-none"></app-menu>
                    </div>
                </nav>
            </div>
        </div>
        <div class="row d-md-none" style="border-top: 1px solid #ddd;padding:0">
            <div class="col py-3">
                <nav style="justify-content: center">
                    <div class="search-product d-flex d-sm-flex d-md-none">
                        <input [(ngModel)]="searchText" type="text" placeholder="Aradığınız ürün, kategori veya markayı yazınız" (keyup.enter)="search()">
                        <button (click)="search()">
                            <img src="assets/images/icon/search-icon.png" width="20" height="20" draggable="false" style="user-select: none;">
                        </button>
                    </div>
                </nav>
            </div>
        </div>
        <div class="row d-none d-lg-block" style="border-top: 1px solid #ddd;height:50px;padding:0">
            <div class="col">
                <nav style="justify-content: center;height: 50px;">
                    <app-menu></app-menu>
                </nav>
            </div>
        </div>
    </div>
</header>
<!-- Nav end-->