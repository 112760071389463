import { Component, OnInit } from '@angular/core';
import { AppService } from './shared/service/app.service';
import { SettingsService } from './shared/service/settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  uuid = localStorage.getItem('uuid');

  theme;
  
  constructor(
    private appService:AppService,
    private settingsService: SettingsService,
  ) {
    if (!this.uuid) {
      localStorage.setItem('uuid', Math.random().toString());
    }

    this.appService.settings().subscribe(res => {});
    this.theme = settingsService.theme;
    localStorage.removeItem('preview');
  }

  ngOnInit() {
    document.querySelector('title#title').textContent = this.theme.title;
    document.querySelector('link#icon').setAttribute('href', this.theme.favicon);
  }

}
