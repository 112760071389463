import { Component, OnInit } from '@angular/core';
import { CartService } from '../../../../shared/service/cart.service';
import { Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from 'src/app/shared/service/settings.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  public cartItems: Observable<any[]> = of([]);
  public shoppingCartItems: any[] = [];
  title;
  theme;

  constructor(
    private cartService: CartService,
    private toastr:ToastrService,
    private settingsService: SettingsService,
    private router: Router
  ) {
    this.title = settingsService.companyInfos.name;
    this.theme = settingsService.theme;
  }

  ngOnInit() {
    this.cartService.getCart().subscribe(res => {
      if(res['result']['status']) {
        this.shoppingCartItems = res['data'];
      }
    })
    if(this.theme.type == 'gallery') {
      this.router.navigateByUrl('');      
    }
  }

  get getSubTotal(): number {
    let subtotal = 0;
    for (const item of this.shoppingCartItems) {
      subtotal += item.DiscountAmount;
    }
    return subtotal;
  }

  removeItem(item) {
    let index = this.shoppingCartItems.findIndex(r => item.SepetGuid == r.SepetGuid);
    if(index >= 0) {
      this.cartService.deleteRow(item.SepetGuid).subscribe(res => {
        if (res['status']) {
          this.shoppingCartItems.splice(index, 1);
          this.toastr.success(res['description']);
        } else {
          this.toastr.error(res['description']);
        }
      })
    }
  }

  public increment(item: any) {
    if(item.LiveQuantity > item.Quantity) {
      item.Quantity++;
      this.updateCardRow(item);
    }else {
      this.toastr.info('Yeterli stok bulunmamaktadır !!!');
    }
  }

  public decrement(item: any) {
    if (item.Quantity > 1) {
      item.Quantity--;
      this.updateCardRow(item);
    } else {
      this.toastr.info('Miktarı sıfırlamak yerine ürünü sepetten silin !!!');
    }
  }

  updateCardRow(item:any) {
    let product = { ItemGuid: item.ItemGuid, Quantity: item.Quantity, SepetGuid: item.SepetGuid };
    this.cartService.addCart(product, '').subscribe(res => {
      if (res['status']) {
        this.toastr.success(res['description']);
      } else {
        this.toastr.error(res['description']);
      }
    })
  }


}
