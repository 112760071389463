<div class="collection-mobile-back">
    <span class="filter-back" (click)="toggleFilter()">
    <i class="fa fa-angle-left" aria-hidden="true"></i> Geri
</span>
</div>
<div class="collection-collapse-block top_filter border-0 open">
    <h3 class="collapse-block-title">KOLEKSİYONLAR</h3>
    <div class="collection-collapse-block-content">
        <div class="collection-brand-filter">
            <ul class="category-list">
                <li *ngFor="let collection of collections">
                    <a (click)="changeCollection(collection)">{{collection.Item.KoleksiyonAdi}}</a>
                </li>
            </ul>
        </div>
    </div>
</div>