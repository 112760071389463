<!--megamenu section Start-->
<header class="dark loding-header custom-scroll" [ngClass]="url == '/portfolio/portfolio-parallex' || url == '/portfolio/centered-slide' || url == '/portfolio/multiple-carousel' ?'bg-white':''">
    <div class="container">
        <div class="row">
            <div class="col">
                <nav>
                    <a [routerLink]="['/enterprice']"  class="d-inline-block m-r-auto"><img src="assets/images/logo/5.pngz" alt="" class="img-fluid brand-logo"></a>
                    <app-menu></app-menu>
                </nav>
            </div>
        </div>
    </div>
</header>
<!--megamenu section end-->