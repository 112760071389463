<footer class="ecommerce-footer" id="footer">
    <div class="container">
        <div class="row footer-theme partition-f">
            <div class="col-lg-4 col-md-12">
                <div class="footer-contant">
                    <div class="footer-logo" *ngIf="theme.logo.selected == 'image'"><a [routerLink]="['']" class="logo samsung-font-bold"><img [src]="theme.logo.footerLogo" [alt]="theme.logo.text"></a></div>
                    <div class="footer-logo" *ngIf="theme.logo.selected == 'text'"><a [routerLink]="['']" class="logo samsung-font-bold">{{ theme.logo.text }}</a></div>
                    <div class="footer-social">
                        <ul *ngIf="(company.social) || company.social.length > 0">
                            <li *ngFor="let item of company.social"><a href="{{ item.link }}"><i aria-hidden="true" class="{{ item.icon }}"></i></a></li>
                        </ul>
                        <ul *ngIf="(!company.social) || company.social.length == 0">
                            <li><a href="javascript:void()" href="https://www.facebook.com/ZaferCicekcilikLtdSti/"><i aria-hidden="true" class="fa fa-facebook"></i></a></li>
                            <li><a href="javascript:void()"><i aria-hidden="true" class="fa fa-google-plus"></i></a></li>
                            <li><a href="javascript:void()"><i aria-hidden="true" class="fa fa-twitter"></i></a></li>
                            <li><a href="javascript:void()" href="https://www.instagram.com/zafercicekcilikk/?hl=tr"><i aria-hidden="true" class="fa fa-instagram"></i></a></li>
                            <li><a href="javascript:void()"><i aria-hidden="true" class="fa fa-rss"></i></a></li>
                        </ul>
                    </div>
                    <!-- <div class="mobile-app-links">
                        <h4>
                            Mobil Uygulama Linklerimiz:
                        </h4>
                        <div class="row">
                            <div class="col-6">
                                <a href="https://apps.apple.com/us/app/kurumsal-reyon/id1499496462?l=tr&ls=1" target="_blank">
                                    <img src="assets/images/appstore.png" alt="Kurumsal Reyon AppStore">
                                </a>
                            </div>
                            <div class="col-6">
                                <a href="https://play.google.com/store/apps/details?id=com.glopark.destech" target="_blank">
                                    <img src="assets/images/googleplay.png" alt="Kurumsal Reyon Google Play">
                                </a>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="col offset-xl-1">
                <div class="sub-title">
                    <div class="footer-title">
                        <h4>Hesabım</h4>
                    </div>
                    <div class="footer-contant">
                        <ul>
                            <li><a href="javascript:void()" [routerLink]="['/hesabim']">Bilgilerim</a></li>
                            <li><a href="javascript:void()" [routerLink]="['/siparislerim']">Siparişlerim</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="sub-title">
                    <div class="footer-title">
                        <h4>Müşteri Hizmetleri</h4>
                    </div>
                    <div class="footer-contant">
                        <ul>
                            <li *ngFor="let cs of customerService.csList" id="title-h"><a [routerLink]="['/pages', convertString(cs.Baslik)]">{{turkLower(cs.Baslik)}}  </a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="sub-title">
                    <div class="footer-title">
                        <h4>Mağaza İletişim</h4>
                    </div>
                    <div class="footer-contant">
                        <ul class="contact-list">
                            <li *ngIf="company.contact.fullName != '' || company.contact.fullName" class="pl-0">{{ company.contact.fullName }}</li>
                            <li><i class="fa fa-map-marker"></i>{{ company.contact.address }}</li>
                            <li><i class="fa fa-phone"></i><p *ngFor="let phone of company.contact.phones" class="mb-2" style="font-weight: 500;">{{ phone.title }}: <a href="tel:{{ phone.no }}">{{ phone.no }}</a></p></li>
                            <li *ngIf="company.contact.fax != '' || company.contact.fax"><i class="fa fa-fax"></i>Fax: {{ company.contact.fax }}</li>
                            <li><i class="fa fa-envelope-o"></i>Email: <a href="mailto:infos.contact.email">{{ company.contact.email }}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="sub-footer">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="footer-end row">
                        <p class="pt-0"><small><i aria-hidden="true" class="fa fa-copyright"></i> Copyright 2020 <a href="https://www.glopark.com/" target="_blank" class="copyright-link"><small>Glopark</small></a> | Tüm Hakları Saklıdır. Kredi kartı bilgileriniz 256bit
                            SSL sertifikası ile korunmaktadır.</small>
                        </p>
                        <div class="card-brands d-flex">
                            <img src="assets/images/icon/trushsafe-logo.jpg" alt="master">
                            <img src="assets/images/icon/master-logo.png" alt="master">
                            <img src="assets/images/icon/visa-logo.png" alt="master">
                            <img src="assets/images/icon/troy-logo.png" alt="master">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<app-tap-to-top></app-tap-to-top>
<!-- <a [href]="sanitize(whatsapp)" class="whatsapp-btn">
    <i aria-hidden="true" class="fa fa-whatsapp"></i>
</a> -->