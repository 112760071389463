import { Component, OnInit, Input } from '@angular/core';
import { ProductsService } from '../../../../service/products.service';
import { CartService } from '../../../../service/cart.service';
import { NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-quick-review',
    templateUrl: './quick-preview-modal.component.html',
    styleUrls: ['./quick-preview-modal.component.scss']
})

export class QuickPreviewModalComponent implements OnInit {
    @Input() handleUrl;
    product;
    productAttributes; any;
    selectedAttributes = {};
    maxAmount = 0;
    closeResult: string;
    public selectedSize: any = '';
    public counter: number = 1;
    constructor(
        public productsService: ProductsService,
        private cartService: CartService,
        public modal: NgbActiveModal,
        private toastr:ToastrService
    ){ }

    ngOnInit() {
        this.productsService.getProductDetail(0, this.handleUrl).subscribe(res => {
            this.product = res;
            this.getAttributeArrays(this.product.stockItem);
        })
    }

    getAttributeArrays(stockItems) {
        this.productAttributes = this.productsService.getProductAttributes(stockItems);
        this.productAttributes.sort((a, b) => { return a['name'] < b['name'] ? 1 : a['name'] === b['name'] ? 0 : -1 })
        for (const patt of this.productAttributes) {
            if (patt.values.length == 1) {
                this.selectedAttributes[patt.name] = patt.values[0].value;
                this.onChangeAttribute(patt.name)
            }
        }
        if (this.productAttributes.length == 0) {
            this.maxAmount = stockItems[0].quantity;
        }else {
            this.maxAmount = 1;
        }
        
    }

    onChangeAttribute(att) {
        let tempAtt = {};
        Object.assign(tempAtt, this.productAttributes.find(pa => { return pa.name == att }));
        this.productAttributes = this.productsService.getProductAttributes(this.productsService.getFilteredStockItems(this.product.stockItem, att, this.selectedAttributes[att]));

        for (let i = 0; i < this.productAttributes.length; i++) {
            if (this.productAttributes[i].name == att) {
                this.productAttributes[i] = tempAtt
            }
        }

        this.getAmount(att);
    }

    getAmount(attName) {
        let att = this.productAttributes.find(pa => { return pa.name == attName })

        let amount = att.values.find(a => {
            return a.value == this.selectedAttributes[attName]
        }).amount;
        this.maxAmount = parseInt(amount);//Array.from(new Array(amount), (val, index) => index + 1) || [1];
    }

    close(reason) {
        this.modal.close(this.getDismissReason(reason));
    }

    public addToCart() {
        let itemGuid = this.productsService.findStockItem(this.product.stockItem, this.selectedAttributes);
        let card_product = { ItemGuid: itemGuid, Quantity: this.counter };

        this.cartService.addCart(card_product, '').subscribe(res => {
            if(res.status) {
                this.cartService.cartItemCount.next(1);
                this.toastr.success(res.description);
            }else {
                this.toastr.error(res.description);
            }
        })
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    public changeVariantSize(patt, pav) {
        this.selectedAttributes[patt.name] = pav.value;
        this.onChangeAttribute(patt.name);
    }


    public increment() {
        if(this.counter < this.maxAmount) {
            this.counter += 1;
        }else {
            this.toastr.info('Yeterli stok bulunmamaktadır !!!')
        }
    }

    public decrement() {
        if (this.counter > 1) {
            this.counter -= 1;
        }
    }
}