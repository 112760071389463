import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService } from '../../../../shared/service/cart.service';
import { ProductsService } from 'src/app/shared/service/products.service';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from 'src/app/shared/service/settings.service';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {
  public url: any;
  public product: any;
  hUrl;
  imgremv;
  productAttributes; any;
  selectedAttributes = {};
  maxAmount = 0;
  counter = 1;
  title;
  productSliderOptions = {
    items: 1,
    nav: false,
    dots: false,
    autoplay: false,
    slideSpeed: 300,
    loop: true
  }
  productSliderOptions1 = {
    items: 3,
    loop: true,
    margin: 10,
    dots: false,
    width:130,
  }
  constructor(
    private activatedRoute: ActivatedRoute, private router: Router,
    private cartService: CartService,
    private productsService: ProductsService,
    private toastr: ToastrService,
    private settingsService:SettingsService
    ) {
      this.title = this.settingsService.companyInfos.name;
    this.activatedRoute.params.subscribe(params => {
      const id = params['id'] !== undefined ? params['id'] : 0;
      activatedRoute.paramMap.subscribe(arg => {
        this.hUrl = arg.get('handleUrl')
      })
      this.productsService.getProductDetail(id, this.hUrl).subscribe(res => {
        this.product = res;
        console.log(res.image)
        if(res.image.length == 1){
          this.imgremv = true;
        }
        else{
          this.imgremv = null;
        }
        this.getAttributeArrays(this.product.stockItem);
      })
    });
  }

  ngOnInit() { }

  getAttributeArrays(stockItems) {
    this.productAttributes = this.productsService.getProductAttributes(stockItems);
    this.productAttributes.sort((a, b) => { return a['name'] < b['name'] ? 1 : a['name'] === b['name'] ? 0 : -1 })
    for (const patt of this.productAttributes) {
      if (patt.values.length == 1) {
        this.selectedAttributes[patt.name] = patt.values[0].value;
        this.onChangeAttribute(patt.name)
      }
    }
    if (this.productAttributes.length == 0) {
      this.maxAmount = stockItems[0].quantity;
    } else {
      this.maxAmount = 1;
    }

  }

  onChangeAttribute(att) {
    let tempAtt = {};
    Object.assign(tempAtt, this.productAttributes.find(pa => { return pa.name == att }));
    this.productAttributes = this.productsService.getProductAttributes(this.productsService.getFilteredStockItems(this.product.stockItem, att, this.selectedAttributes[att]));

    for (let i = 0; i < this.productAttributes.length; i++) {
      if (this.productAttributes[i].name == att) {
        this.productAttributes[i] = tempAtt
      }
    }

    this.getAmount(att);
  }

  getAmount(attName) {
    let att = this.productAttributes.find(pa => { return pa.name == attName })

    let amount = att.values.find(a => {
      return a.value == this.selectedAttributes[attName]
    }).amount;
    this.maxAmount = parseInt(amount);//Array.from(new Array(amount), (val, index) => index + 1) || [1];
  }

  public changeVariantSize(patt, pav) {
    this.selectedAttributes[patt.name] = pav.value;
    this.onChangeAttribute(patt.name);

    //let att = this.productAttributes.find(pa => { return pa.name == patt.name })
    this.product.stockItem.forEach(element => {
      let att = element.stockItemAttribute.find(pa => { return pa.name == patt.name && pa.value == pav.value })
      if (att) {
        if (element.id == att.stockItemId) {
          this.product.displayPrice = element.displayPrice;
          this.product.salePrice = element.salePrice;
        }
      }
    });

  }


  public increment() {
    if (this.counter < this.maxAmount) {
      this.counter += 1;
    } else {
      this.toastr.info('Yeterli stok bulunmamaktadır !!!')
    }
  }

  public decrement() {
    if (this.counter > 1) {
      this.counter -= 1;
    }
  }

  public addToCart(buy?) {
    let itemGuid = this.productsService.findStockItem(this.product.stockItem, this.selectedAttributes);
    let card_product = { ItemGuid: itemGuid, Quantity: this.counter };

    this.cartService.addCart(card_product, '').subscribe(res => {
      if (res.status) {
        this.cartService.cartItemCount.next(1);
        this.toastr.success(res.description);
        if (buy) {
          this.router.navigate(['/sepetim']);
        }
      } else {
        this.toastr.error(res.description);
      }
    })
  }

  buy() {
    this.cartService.getCart().subscribe(arg => {
      let itemGuid = this.productsService.findStockItem(this.product.stockItem, this.selectedAttributes);
      let card_product = { ItemGuid: itemGuid, Quantity: this.counter };

      if (arg['data'].length === 0) {
        this.addToCart(true);
      }
      else if (arg['data'].length > 0) {
        arg['data'].forEach(item => {
          if (card_product.ItemGuid != item.ItemGuid) {
            this.addToCart(true);
          }
          else {
            this.router.navigate(['/sepetim']);
          }
        });
      }
    })
  }
}
