import { Component, OnInit } from '@angular/core';
import { CollectionService } from '../../../service/collection.service';
import { ActivatedRoute } from '@angular/router';
import { FilterService } from 'src/app/shared/service/filter.service';

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss']
})
export class CollectionComponent implements OnInit {
  collections;
  constructor(
    public filterService:FilterService,
    public collectionService: CollectionService
    ) { 
      this.collections = [];
    }
  
  // collapse toggle
  ngOnInit() { 
    this.collectionService.collectionListDetector.subscribe(res => {
      if(res) {
        if (res.Children && res.Children.length > 0) {
          this.collections = res.Children;
          this.collectionService.collectionChangeDetector.next(res);
        }else {
          this.collectionService.collectionChangeDetector.next(res);
        }
      }else {
        this.getCollections();
      }
    }) 
  }

  // For mobile view
  public mobileFilterBack() {  }
 
  toggleFilter(){
    this.filterService.filterBar = !this.filterService.filterBar
  }

  getCollections() {
    this.collectionService.listTree().subscribe(res => { 
      this.collections = res;
    });
  }

  changeCollection(collection)  {
    console.log(this.collectionService.collectionChangeDetector.next(collection.Item));
  }
   
}
