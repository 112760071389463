import { Injectable, Inject } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountService } from './account.service';
import { Constants } from '../constants';

@Injectable()
export class CollectionService {
    collections:any;
    collectionTree:any;
    mainCollections:Array<any>;
    collectionType = { story : '0', main : '1'};
    storyList:Array<any>;
    collectionChangeDetector = new BehaviorSubject(null);
    collectionListDetector = new BehaviorSubject(null);
    productListDetector = new BehaviorSubject(null);
    
    constructor(
        private http: HttpClient,
        private accountService: AccountService
    ){ }

    list(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Basic bW9iaW1ib19kZXY6YTV6eGZ0N1Ah',
                'Content-Type': 'application/json'
            })
        }
        return this.http.get(`${Constants.b2cApiUrl}/collection/list?companyId=${this.accountService.getAccount['CompanyId']}`, httpOptions)
        .pipe(map(res => {
            if (res['status'] == 200) {
                this.collections = JSON.parse(res['_body']);
                return this.collections;
            }else {
                return [];
            }
        }))
    }

    listTree(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Basic bW9iaW1ib19kZXY6YTV6eGZ0N1Ah',
                'Content-Type': 'application/json'
            })
        }
        let companyId = this.accountService.getAccount ? this.accountService.getAccount['CompanyId'] : 0;
        return this.http.get(`${Constants.b2cApiUrl}/collection/list/tree?companyId=${companyId}`, httpOptions)
            .pipe(map(res => {
                if (res['status']) {
                    this.collectionTree = res['data'];
                    this.collections = this.collectionTree.reduce((collections, c) => { 
                        if(c.Children.length > 0) {
                            c.Item['Children'] = c.Children;
                            collections.push(c.Item);
                            collections.push(...c.Children.map(c => c.Item));
                            return collections
                        }else {
                            collections.push(c.Item);
                            return collections 
                        }
                        
                    }, []);
                    
                    this.storyList = this.getStory.sort((a,b) => { return a.Sira - b.Sira});
                    this.mainCollections = this.getMainCollection.sort((a, b) => { return a.Sira - b.Sira });
                    return res['data'];
                }
            }))
    }

    set setcollections(c) {
        this.collections = c;
    }

    get getCollection():any {
        return this.collections;
    }

    get  getStory():any {
        if(!this.collectionTree) {
            return [];
        }else{
            return this.collections.filter(c => {
                return c.GosterimYeri == this.collectionType.story
            });
        }
        
    }

    get getMainCollection(): any {
        if (!this.collectionTree) {
            return [];
        }else{
            return this.collections.filter(c => {
                return c.GosterimYeri == this.collectionType.main
            });
        }
        
    }

    collectionWithChildren(id) {
        return this.collectionTree.find(c => { return c.Item.Id == id });
    }
}