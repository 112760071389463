import { Component, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/shared/service/customer-service';
import { DomSanitizer } from '@angular/platform-browser';
import { SettingsService } from 'src/app/shared/service/settings.service';

@Component({
  selector: 'app-ecommerce-footer',
  templateUrl: './ecommerce-footer.component.html',
  styleUrls: ['./ecommerce-footer.component.scss']
})
export class EcommerceFooterComponent implements OnInit {
  settings = {};
  whatsapp = '';
  company;
  theme;
  constructor(
    public customerService: CustomerService,
    private sanitizer: DomSanitizer,
    private settingsService: SettingsService
  ) {
    this.company = settingsService.companyInfos;
    this.theme = settingsService.theme;
  }

  ngOnInit() {
    this.customerService.list().subscribe(res => { });
    this.settings = JSON.parse(localStorage.getItem('settings')) || {};
    this.whatsapp = `whatsapp://send?phone=${this.settings['whatsapptel']}&abid=${this.settings['whatsapptel']}`;
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
