<div class="modal-body">
    <button type="button" class="close" (click)="close('Save click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="row align-items-center" *ngIf="product">
        <div class="col-lg-6  col-xs-12">
            <div class="quick-view-img">
                <img [src]="product.image[0]?.url" alt="" class="img-fluid">
            </div>
        </div>
        <div class="col-lg-6 rtl-text">
            <div class="product-right">
                <h2>{{product?.title}}</h2>
                <h3 class="price">{{product?.salePrice}} TL 
                    <del *ngIf="product?.displayPrice > product?.salePrice">
                        <span class="money">{{product?.displayPrice}} TL</span>
                    </del>
                </h3>
                <!-- <ul class="color-variant" *ngIf="product.variants && !productsService?.catalogMode">
                    <li [class]="variant.color" [ngStyle]="{'background-color': variant.color}" *ngFor="let variant of product.variants let i = index"></li>
                </ul> -->
                <div class="border-product">
                    <h6 class="product-title">Ürün Detay</h6>
                    <div style="overflow-x: auto;overflow-y: auto;max-height: 200px" [innerHtml]="product?.description"></div>
                </div>
                <div class="product-description border-product quantity">
                    <div class="size-box" *ngFor="let patt of productAttributes">
                        {{patt.name}}
                        <ul>
                            <li [ngClass]="{'active': selectedAttributes[patt.name] == paV.value}" *ngFor="let paV of patt.values">
                                <a (click)="changeVariantSize(patt, paV)">{{paV.value}}</a>
                            </li>
                        </ul>
                    </div>
                    <h5 class="avalibility" *ngIf="maxAmount != 0"><span>Stoklarda</span></h5>
                    <h5 class="avalibility text-danger" *ngIf="maxAmount == 0"><span>Stokda yok</span></h5>
                    <!-- <h6 class="product-title">Stok Adedi</h6> -->
                    <div class="qty-box" *ngIf="maxAmount != 0">
                        <div class="input-group">
                            <span class="input-group-prepend">
                                <button type="button" class="btn quantity-left-minus" (click)="decrement()"
                                    data-type="minus" data-field="">
                                    <i class="fa fa-chevron-left"></i>
                                </button>
                            </span>
                            <input type="text" name="quantity" disabled class="form-control input-number" value="{{counter}}">
                            <span class="input-group-prepend">
                                <button type="button" class="btn quantity-right-plus" (click)="increment()"
                                    data-type="plus" data-field="">
                                    <i class="fa fa-chevron-right"></i>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="product-buttons" *ngIf="maxAmount != 0">
                    <a (click)="addToCart()" class="btn btn-default primary-btn radius-0">Sepete Ekle</a>
                    <a [routerLink]="['/product/', product.handleUrl]" (click)="close('Save click')" class="btn btn-default primary-btn radius-0">Detaya Git</a>
                </div>
            </div>
        </div>
    </div>
</div>