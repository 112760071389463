import { Component, OnInit, Input } from '@angular/core';
import { ProductsService } from '../../../service/e-commerce/products.service';
import { CartService } from '../../../service/e-commerce/cart.service';
import { WishListService } from '../../../service/e-commerce/wish-list.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuickPreviewModalComponent } from './quick-preview-modal/quick-preview-modal.component';
import { SettingsService } from 'src/app/shared/service/settings.service';

@Component({
  selector: 'app-product-box',
  templateUrl: './product-box.component.html',
  styleUrls: ['./product-box.component.scss']
})
export class ProductBoxComponent implements OnInit {
  theme;

  @Input() products: any;
  constructor(
    public productsService: ProductsService,
    private modalService: NgbModal,
    private cartService:CartService,
    private wishlistService:WishListService,
    private settingsService:SettingsService
  ) {
    this.theme = settingsService.theme;
    setTimeout(() => {console.log(this.products)}, 1000)
  }

  ngOnInit() { }

  openQuickPreview(handleUrl) {
    let modalRef = this.modalService.open(QuickPreviewModalComponent, { size: 'lg', ariaLabelledBy: 'modal-basic-title' });
    modalRef.componentInstance.handleUrl = handleUrl;

    modalRef.result.then(result => { console.log(result) }).catch(err => { /*console.log(err)*/ });
  }

  public addToCart(product: any, quantity: number = 1) {
    this.cartService.addToCart(product, quantity);
  }

  public addToCompare(product: any) {
    this.productsService.addToCompare(product);
  }

  // Add to wishlist
  public addToWishlist(product: any) {
    this.wishlistService.addToWishlist(product);
  }

}
