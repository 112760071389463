<a class="overlay-sidebar-header" [class.overlay-sidebar-header-open]="openSide" (click)="closeOverlay()"></a>
<div class="responsive-btn">
    <a (click)="toggleSidebar()" style="font-size: 14px; padding: 5px;">
        <i class="fa fa-bars text-white" aria-hidden="true"></i>
    </a>
</div>
<div class="navbar m-l-auto" id="togglebtn" [class.openSidebar]="openSide">
    <div class="responsive-btn">
        <a (click)="toggleSidebar()">
            <h5>Kapat</h5>
        </a>
    </div>
    <ul class="main-menu fill">
        <!-- 1st Level Menu -->
        <li *ngFor="let menuItem of collectionService.storyList" [class]="menuItem.megaMenu ? 'mega-menu' : ''" style="text-align:left !important;">
            <!-- Sub -->
            <a [routerLink]="['/collection/' + menuItem.HandleUrl]" >
                {{menuItem.KoleksiyonAdi}}
            </a>
            <!-- 2nd Level Menu -->
            <ul [class.opensubmenu]="isActive(menuItem.KoleksiyonAdi)">
                <!-- Simple Menu Start-->
                <li *ngFor="let collection of collectionService.collectionWithChildren(menuItem.Id).Children" [class.sub-menu]="collection.Children">
                    <!-- Sub -->
                    
                        <div class="row">
                            <div class="col-8">
                                <a [routerLink]="['/collection/' + collection.Item.HandleUrl]"> {{collection.Item.KoleksiyonAdi}} </a>
                            </div>

                            <div class="col-4 icon">
                                <div><i class="fa fa-chevron-down"></i></div>
                            </div>
                        </div>
                    

                    <ul style="top:0 !important;">
                        <li *ngFor="let item of collection.Children">
                            <a [routerLink]="['/collection/' + item.Item.HandleUrl]">{{item.Item.KoleksiyonAdi}}</a>
                        </li>

                    </ul>
                </li>
            </ul>
        </li>
    </ul>

    <div id="nav-s" class="container bg-dark mb-auto" style="position: absolute;bottom:75px;">
        <div class="col-12">
            <a class="mx-2 text-white" href="https://www.facebook.com/ozlemkitapkirtasiye.denizli"><i aria-hidden="true" class="fa fa-facebook"></i></a>
            <a class="mx-2 text-white" href="https://www.instagram.com/ozlemkitapkirtasiye/"><i aria-hidden="true" class="fa fa-instagram"></i></a>
        </div>
    </div>
</div>